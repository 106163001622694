/*
(function () {
	function ChangeLinkWA() {
		this.text = 'Обращение: {wz_metric} \n\nЯ хочу получить консультацию эксперта'
		this.cookieSource = '_ym_uid'
	}
	ChangeLinkWA.prototype.editLink = function (url, id) {
		if (decodeURIComponent(url.split('text=')[1]) === this.text.replace(/{wz_metric}/gi, id)) {
			return
		}
		let regexNumberPhone = /\d+/
		if (!regexNumberPhone.test(url)) {
			return
		}
		let phone = url.match(regexNumberPhone)[0]
		let host = url.split(phone)[0]
		let newUrl = host === 'https://wa.me/' ? `${host.toString() + phone.toString()}?text=${this.text.replace(/{wz_metric}/gi, id)}` : `${host.toString() + phone.toString()}&text=${this.text.replace(/{wz_metric}/gi, id)}`
		$('.js-whatsapp-button').attr('wz-metric', id)

		return newUrl
	}
	ChangeLinkWA.prototype.getCookie = function (name) {
		let cookie = document.cookie
		let matches = cookie.match(new RegExp(`(?:^|; )${name.replace(/([.$?*|{}()[]\/+^])/g, '\\$1')}=([^;]*)`))

		return matches ? decodeURIComponent(matches[1]) : undefined
	}
	ChangeLinkWA.prototype.censusLinks = function () {
		let links = document.querySelectorAll('[href*="//wa.me"], [href*="//api.whatsapp.com/send"], [href*="//web.whatsapp.com/send"], [href^="whatsapp://send"], .wa__button')
		let id = this.getCookie(this.cookieSource)
		let that = this
		links.forEach((link) => {
			let newLink = that.editLink(link.href, id)
			if (newLink) {
				link.href = newLink
			}
		})
	}

	if (!(window.__wz_scripts && window.__wz_scripts.scriptsChangeLinkWA)) {
		if (!window.__wz_scripts) {
			window.__wz_scripts = {}
		}
		window.__wz_scripts.scriptsChangeLinkWA = new ChangeLinkWA()
		var interval = setInterval(() => {
			let id = window.__wz_scripts.scriptsChangeLinkWA.getCookie(window.__wz_scripts.scriptsChangeLinkWA.cookieSource)
			if (id) {
				clearInterval(interval)
				window.__wz_scripts.scriptsChangeLinkWA.censusLinks()
				$('.moredetails').attr('data-wz-metric', id)
				$('.js-whatsapp-button').attr('data-wz-metric', id)
			}
		}, 200)
	}
})()

function addWhatsappClick(ymCode, wa_type, wz_metric, text, uniq = false) {
	let currentUrl = `${document.location.protocol}//${document.location.host
		}${document.location.pathname}`
	$.ajax({
		url: 'amo/handle_whatsapp.php',
		type: 'post',
		dataType: 'json',
		data: {
			ym_code: ymCode,
			url: currentUrl,
			wa_type,
			wz_metric,
			text
		},
		success(data) {
			if (data.success) {
				// ok
				console.log('whatsapp sended', data.id)
			} else {
				// описание искать в файле лога на сервере
				console.log('whatsapp not sended')
			}
		},
		error(data) {
			console.log('error', data)
		},
		always(data) {
			// location = 'https://api.whatsapp.com/send/?phone=79295888372&text=' + encodeURI($el.attr('data-wa-text').replace('{wz_metric}', $el.attr('data-wz-metric'))) + '&type=phone_number&app_absent=0';
		}
	})
}

$('.js-whatsapp-button').click(function (e) {
	e.preventDefault()

	let textdop = $(this).attr('data-main-text')
	//let metric = $(this).attr('wz-metric')
	$(this).attr('data-wa-text', `${textdop}`)

	let $el = $(this)
	let ymCode = typeof $el.data('ym-code') !== 'undefined' ? $el.data('ym-code') : ''
	let text = $el.attr('data-wa-text').replace('{wz_metric}', $el.attr('data-wz-metric'))

	if (typeof ym !== 'undefined' && ymCode !== '') {
		ym(12923497, 'reachGoal', ymCode)
	}

	addWhatsappClick(ymCode, $el.data('wa-type'), $el.attr('data-wz-metric'), text)

	let path = `https://api.whatsapp.com/send/?phone=77778269400&text=${encodeURI(text)}&type=phone_number&app_absent=0`
	location = path
})
*/
$(document).ready(() => {
	function utm($this) {
		$this.find('input.js-utm_source:hidden').val(localStorage.utm_source)
		$this.find('input.js-utm_medium:hidden').val(localStorage.utm_medium)
		$this.find('input.js-utm_campaign:hidden').val(localStorage.utm_campaign)
		$this.find('input.js-utm_term:hidden').val(localStorage.utm_term)
		$this.find('input.js-utm_content:hidden').val(localStorage.utm_content)
		$this.find('input.js-utm_referrer:hidden').val(localStorage.utm_referrer)
		$this.find('input.js-_ym_uid:hidden').val(localStorage._ym_uid)
		$this.find('input.js-yclid:hidden').val(localStorage.yclid)
	}
	
	$('#modal-wz').submit(function (e) {
		e.stopPropagation()
		e.preventDefault()

		$('#modal-wz').find('input[type=submit], button[type=submit]').prop('disabled', true);

		let valid = true

		let msg = $('#modal-wz input[name="msg"]')
		let comment = $('#modal-wz input[name="comment"]')
		msg.value = `Заявка с modal-wz | ${comment.val()}`

		$('#modal-wz').find('input').each(
			function (value) {
				if (!valid) {
					// break
					return false
				}
				if (value.type === 'submit') {
					// skip
					return true
				}
				let $el = $(value)

				if ($el.hasClass('required') && $el.val().trim() === '') {
					valid = false
					$(this).addClass('error')

					return false
				}
			}
		)

		utm($(this))

		if (valid) {
			$.post(
				'/amo/amo.php', // адрес обработчика
				$('#modal-wz').serialize(), // отправляемые данные
			)
				.done(() => {
					$('#modal-wz > input[name=tel]').val('');
					$('#modal-wz').find('input[type=submit], button[type=submit]').prop('disabled', false);
					$('#ws').modal('toggle')
					window.location.href = "https://digitaladsystems.com/thank-you.html";
				})
				.fail((xhr, status, error) => {
					console.log(xhr)
					console.log(status)
					console.log(error)
				})
		}
	})	
})

$(document).ready(() => {
	//form settings
    $('body').on('input', '[name="tel"]', function(){
        this.value = this.value.replace(/[^\+\d]/g, '');
        if($(this).val().length > 12){
            $(this).val($(this).val().slice(0, 12));
        }
    });

    $('body').on('input', '[name="name"]', function(){
        this.value = this.value.replace(/[\W\d]/g, '');
        if($(this).val().length > 20){
            $(this).val($(this).val().slice(0, 20));
        }
    });
	
	function utm($this) {
		$this.find('input.js-utm_source:hidden').val(localStorage.utm_source)
		$this.find('input.js-utm_medium:hidden').val(localStorage.utm_medium)
		$this.find('input.js-utm_campaign:hidden').val(localStorage.utm_campaign)
		$this.find('input.js-utm_term:hidden').val(localStorage.utm_term)
		$this.find('input.js-utm_content:hidden').val(localStorage.utm_content)
		$this.find('input.js-utm_referrer:hidden').val(localStorage.utm_referrer)
		$this.find('input.js-_ym_uid:hidden').val(localStorage._ym_uid)
		$this.find('input.js-yclid:hidden').val(localStorage.yclid)
	}
	
	$('#callbackForm').submit(function (e) {
		e.stopPropagation()
		e.preventDefault()

		let msg = $('#callbackForm input[name="msg"]')
		let comment = $('#callbackForm input[name="comment"]')
		msg.value = `Заявка с DAS_footer | ${comment.val()}`

		$('#callbackForm').find('input[type=submit], button[type=submit]').prop('disabled', true);

		let valid = true

		$('#callbackForm').find('input').each(
			function (value) {
				if (!valid) {
					// break
					return false
				}
				if (value.type === 'submit') {
					// skip
					return true
				}
				let $el = $(value)

				if ($el.hasClass('required') && $el.val().trim() === '') {
					valid = false
					$(this).addClass('error')

					return false
				}
			}
		)

		utm($(this))

		if (valid) {
			$.post(
				'/amo/amo.php', // адрес обработчика
				$('#callbackForm').serialize(), // отправляемые данные
			)
				.done(() => {
					$('#callbackForm > input[name=tel]').val('');
					$('#callbackForm').find('input[type=submit], button[type=submit]').prop('disabled', false);
					window.location.href = "https://digitaladsystems.com/thank-you.html";
				})
				.fail((xhr, status, error) => {
					console.log(xhr)
					console.log(status)
					console.log(error)
				})
		}
	})	
})
function loadScript() {
	const questions = Array.from(document.getElementsByClassName('quiz__question'))
	const prevButton = document.getElementById('prevButton')
	const nextButton = document.getElementById('nextButton')
	const progressBar = document.getElementById('progress')
	const formContainer = document.getElementById('formContainer')

	let currentQuestion = 0
	let selectedAnswers = []

	// проверка что radio выбран в группе
	function hasSelectedAnswer() {
		const currentQuestionElement = questions[currentQuestion]
		const selectedChoices = Array.from(currentQuestionElement.querySelectorAll(`input[name="choices${currentQuestion + 1}"]`))

		return selectedChoices.some(choice => choice.checked)
	}

	// блокировка кнопок
	function updateNavigationButtons() {
		prevButton.disabled = currentQuestion === 0
		nextButton.disabled = !hasSelectedAnswer()
	}

	// Слушатель чтоб вызывать проверку radio
	function addRadioChangeListeners() {
		const currentQuestionElement = questions[currentQuestion]
		const radioInputs = currentQuestionElement.querySelectorAll(`input[name="choices${currentQuestion + 1}"]`)

		radioInputs.forEach(radioInput => {
			radioInput.addEventListener('change', updateNavigationButtons)
		})
	}

	// Показать следующий вопрос
	function showQuestion() {
		const currentQuestionElement = questions[currentQuestion]
		currentQuestionElement.style.display = 'block'

		updateNavigationButtons()
		addRadioChangeListeners()
	}

	// Обновление ширины прогресс бара
	function updateProgress() {
		const progressPercent = currentQuestion !== 0
			? (currentQuestion + 1) / (questions.length + 1) * 100
			: 1 / (questions.length + 1) * 100
		progressBar.style.width = `${progressPercent}%`
	}

	// Показать предыдущий вопрос
	function showPrevQuestion() {
		if (currentQuestion !== questions.length) {
			questions[currentQuestion].style.display = 'none'
			currentQuestion--
			showQuestion()
			updateProgress()
		} else {
			formContainer.style.display = 'none'
			document.getElementById('progressBar').style.display = 'block'
			nextButton.style.display = 'block'
			currentQuestion = questions.length - 1
			showQuestion()
		}
	}

	// Собрать Ответы из квиза
	function getCheckedCheckBoxes() {
		let checkboxes = document.getElementsByClassName('answer')
		let checkboxesChecked = []

		for (let index = 0; index < checkboxes.length; index++) {
			if (checkboxes[index].checked) {
				checkboxesChecked.push(checkboxes[index].value)
			}
		}

		$('#content-quiz').val(checkboxesChecked)

		return checkboxesChecked
	}

	// Показать форму
	function showForm() {
		document.getElementById('progressBar').style.display = 'none'
		nextButton.style.display = 'none'
		formContainer.style.display = 'block'

		getCheckedCheckBoxes()
	}

	// Показать следующий вопрос
	function showNextQuestion() {
		const currentQuestionElement = questions[currentQuestion]
		const selectedChoices = Array.from(currentQuestionElement.querySelectorAll(`input[name="choices${currentQuestion + 1}"]:checked`))
		const selectedAnswerIndexes = selectedChoices.map(choice => choice.value)

		selectedAnswers = selectedAnswers.concat(selectedAnswerIndexes)

		questions[currentQuestion].style.display = 'none'
		currentQuestion++
		if (currentQuestion < questions.length) {
			showQuestion()
			updateProgress()
		} else {
			showForm()
		}
	}

	document.querySelector('#prevButton').onclick = showPrevQuestion
	document.querySelector('#nextButton').onclick = showNextQuestion

	showQuestion()
	updateProgress()
	addRadioChangeListeners()

	// Обработка формы
	$(document).ready(() => {
		$('#quizForm').submit(() => {
			$.ajax({
				type: 'POST',
				url: 'php/form-quiz.php',
				data: $('#quizForm').serialize(),
				success() {
					$('#quiz').modal('toggle')
					$('#thank').modal('toggle')

					function hideThank() {
						$('#thank').modal('toggle')
					}

					setTimeout(hideThank, 3000)
				}
			})

			return false
		})
	})
}
setTimeout(loadScript, 4000)

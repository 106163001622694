const swiperProducts = new Swiper(".js-horScroll-experts", {
    slidesPerView: "4",
    spaceBetween: 24,
    navigation: {
        nextEl: ".experts__btn.swiper-button-next",
        prevEl: ".experts__btn.swiper-button-prev",
    },
    breakpoints: {
        319: {
            slidesPerView: 'auto',
            spaceBetween: 10,
        },
        1024: {
            slidesPerView: 4,
            spaceBetween: 24,
        },
    },
});
$(document).ready(() => {
    function utm($this) {
        $this.find('input.js-utm_source:hidden').val(localStorage.utm_source)
        $this.find('input.js-utm_medium:hidden').val(localStorage.utm_medium)
        $this.find('input.js-utm_campaign:hidden').val(localStorage.utm_campaign)
        $this.find('input.js-utm_term:hidden').val(localStorage.utm_term)
        $this.find('input.js-utm_content:hidden').val(localStorage.utm_content)
        $this.find('input.js-utm_referrer:hidden').val(localStorage.utm_referrer)
        $this.find('input.js-_ym_uid:hidden').val(localStorage._ym_uid)
        $this.find('input.js-yclid:hidden').val(localStorage.yclid)
    }

    $('#subscribeForm').submit(function (e) {
        e.stopPropagation()
        e.preventDefault()

        let msg = $('#subscribeForm input[name="msg"]')
        let comment = $('#subscribeForm input[name="comment"]')
        msg.value = `Заявка с Subscribe_footer | ${comment.val()}`

        $('#subscribeForm').find('input[type=submit], button[type=submit]').prop('disabled', true);

        let valid = true

        $('#subscribeForm').find('input').each(
            function (value) {
                if (!valid) {
                    // break
                    return false
                }
                if (value.type === 'submit') {
                    // skip
                    return true
                }
                let $el = $(value)

                if ($el.hasClass('required') && $el.val().trim() === '') {
                    valid = false
                    $(this).addClass('error')

                    return false
                }
            }
        )

        utm($(this))

        if (valid) {
            $.post(
                '/amo/amo.php', // адрес обработчика
                $('#subscribeForm').serialize(), // отправляемые данные
            )
                .done(() => {
                    $('#subscribeForm > input[name=email]').val('');
                    $('#subscribeForm').find('input[type=submit], button[type=submit]').prop('disabled', false);
                    window.location.href = "https://digitaladsystems.com/thank-you.html";
                })
                .fail((xhr, status, error) => {
                    console.log(xhr)
                    console.log(status)
                    console.log(error)
                })
        }
    })
})
function loadScriptBlog() {
	const swiperProducts = new Swiper(".js-horScroll-tabs", {
		slidesPerView: "1",
		navigation: {
			nextEl: ".tabs__btn.swiper-button-next",
			prevEl: ".tabs__btn.swiper-button-prev",
		},
	});
	
	$(".js-tab-trigger").click(function () {
		var id = $(this).attr("data-tab"),
			content = $('.js-tab-content[data-tab="' + id + '"]');
	
		$(".js-tab-trigger.active").removeClass("active");
		$(this).addClass("active");
	
		$(".js-tab-content.active").removeClass("active");
		content.addClass("active");
	});
	
	
	
	
	$('.search-field').keypress(function(eventObject){
		var searchTerm = $(this).val();
		
		if(searchTerm.length > 2){
			$.ajax({
				url : '/blog/wp-admin/admin-ajax.php',
				type: 'POST',
				data:{
					'action':'codyshop_ajax_search',
					'term'  :searchTerm
				},
				success:function(result){
					$(".search-items").addClass("active").html(result);
					
					 if( window.innerWidth < 1279 ){
						  $(".bacground").addClass("active");
					 }
				}
			});
		}
	});
	
	const div = document.querySelector( '.search-items');
	
	document.addEventListener( 'click', (e) => {
		const withinBoundaries = e.composedPath().includes(div);
	
		if ( ! withinBoundaries ) {
			$(".search-items").removeClass("active");
			
			if( window.innerWidth < 1279 ){
				$(".bacground").removeClass("active");
			}
		}
	})
}


loadScriptBlog()

// Faq
import './faq/faq'

// Form
import './modals/quiz/quiz'
import './modals/trial/trial'
import './footer/footer'
import './sections/contacts/contacts'

// Hamburger
import './hamburger/hamburger'

//contactUs
import './contactUs/contactUs'

// WhatZupp
import './modals/WhatSapp/WhatSapp'

//Sections
import './sections/experts/experts'



//blog
import './sections/blog/blog-home/blog-home'
